import React, { useEffect } from 'react';
import "../Assets/styles/Career.css"
import { LuClock } from "react-icons/lu";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { IoLocationOutline } from "react-icons/io5";
import { FiArrowUpRight } from "react-icons/fi";
import AllPageLoader from '../Components/AllPageLoader';
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import AOS from "aos";
import "aos/dist/aos.css";


const Career = () => {


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const SplitTypes = document.querySelectorAll('.text-reveal-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                scaleY: 0,
                y: -20,
                transformOrigin: 'top',
                stagger: 0.05
            });
        });
    }, []);

    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.ban-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);



    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.mini-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const elements = document.querySelectorAll('.para-animation');
        elements.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0, y: 20 }, // Start with opacity 0 and slightly below the position
                {
                    opacity: 1,        // End with opacity 1 (fully visible)
                    y: 0,              // Move to the original position
                    duration: 1,       // Adjust duration for smoother transition
                    ease: "power2.out", // Smooth easing
                    scrollTrigger: {
                        trigger: element,
                        start: "top 80%",
                        end: "top 20%",
                        scrub: true,    // One-time animation on scroll
                        markers: false,
                    },
                }
            );
        });
    }, []);



    return (
        <>
        <AllPageLoader />
            <section className='breadcrumb'>
                <div className="container">
                    <div className="row">
                        <div className="breadcrumb-title">
                            <h1 className='ban-title-animation'>Join Our Team</h1>
                            <p data-aos="fade-down" data-aos-duration="2000">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi voluptatibus eius reiciendis magnam.</p>
                        </div>
                        <div className="breadcrumb-title-outline">
                            <h1 className='ban-title-animation'>JoinOurTeam</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className='career-section'>
                <div className="container">
                    <div className="row job-notices" >
                        <div className="col-lg-6">
                            <div className="box-content" data-aos="fade-right" data-aos-duration="3000">
                                <div className="content">
                                    <div className="title">
                                        <h1>Graphic Desinger</h1>
                                        <h6><IoLocationOutline className='icon' />Full Time, Chennai</h6>
                                    </div>
                                    <h6>Poster 2 days ago <span> . 1 Position Available</span></h6>
                                    <p>We are looking for an experienced designer to join our team.</p>
                                    <div className="role-salary">
                                        <h6><LuClock className='icon' />Full Time Position</h6>
                                        <h6 className='salary'><RiMoneyRupeeCircleLine className='icon' />12 LPA</h6>
                                    </div>
                                    <div className="apply-btn">
                                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop">Apply Now <FiArrowUpRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-content" data-aos="fade-left" data-aos-duration="3000">
                                <div className="content">
                                    <div className="title">
                                        <h1>Graphic Desinger</h1>
                                        <h6><IoLocationOutline className='icon' />Full Time, Chennai</h6>
                                    </div>
                                    <h6>Poster 2 days ago <span> . 1 Position Available</span></h6>
                                    <p>We are looking for an experienced designer to join our team.</p>
                                    <div className="role-salary">
                                        <h6><LuClock className='icon' />Full Time Position</h6>
                                        <h6 className='salary'><RiMoneyRupeeCircleLine className='icon' />12 LPA</h6>
                                    </div>
                                    <div className="apply-btn">
                                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop">Apply Now <FiArrowUpRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-content" data-aos="fade-right" data-aos-duration="3000">
                                <div className="content">
                                    <div className="title">
                                        <h1>Graphic Desinger</h1>
                                        <h6><IoLocationOutline className='icon' />Full Time, Chennai</h6>
                                    </div>
                                    <h6>Poster 2 days ago <span> . 1 Position Available</span></h6>
                                    <p>We are looking for an experienced designer to join our team.</p>
                                    <div className="role-salary">
                                        <h6><LuClock className='icon' />Full Time Position</h6>
                                        <h6 className='salary'><RiMoneyRupeeCircleLine className='icon' />12 LPA</h6>
                                    </div>
                                    <div className="apply-btn">
                                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop">Apply Now <FiArrowUpRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-content" data-aos="fade-left" data-aos-duration="3000">
                                <div className="content">
                                    <div className="title">
                                        <h1>Graphic Desinger</h1>
                                        <h6><IoLocationOutline className='icon' />Full Time, Chennai</h6>
                                    </div>
                                    <h6>Poster 2 days ago <span> . 1 Position Available</span></h6>
                                    <p>We are looking for an experienced designer to join our team.</p>
                                    <div className="role-salary">
                                        <h6><LuClock className='icon' />Full Time Position</h6>
                                        <h6 className='salary'><RiMoneyRupeeCircleLine className='icon' />12 LPA</h6>
                                    </div>
                                    <div className="apply-btn">
                                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop">Apply Now <FiArrowUpRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-content" data-aos="fade-right" data-aos-duration="3000">
                                <div className="content">
                                    <div className="title">
                                        <h1>Graphic Desinger</h1>
                                        <h6><IoLocationOutline className='icon' />Full Time, Chennai</h6>
                                    </div>
                                    <h6>Poster 2 days ago <span> . 1 Position Available</span></h6>
                                    <p>We are looking for an experienced designer to join our team.</p>
                                    <div className="role-salary">
                                        <h6><LuClock className='icon' />Full Time Position</h6>
                                        <h6 className='salary'><RiMoneyRupeeCircleLine className='icon' />12 LPA</h6>
                                    </div>
                                    <div className="apply-btn">
                                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop">Apply Now <FiArrowUpRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-content" data-aos="fade-left" data-aos-duration="3000">
                                <div className="content">
                                    <div className="title">
                                        <h1>Graphic Desinger</h1>
                                        <h6><IoLocationOutline className='icon' />Full Time, Chennai</h6>
                                    </div>
                                    <h6>Poster 2 days ago <span> . 1 Position Available</span></h6>
                                    <p>We are looking for an experienced designer to join our team.</p>
                                    <div className="role-salary">
                                        <h6><LuClock className='icon' />Full Time Position</h6>
                                        <h6 className='salary'><RiMoneyRupeeCircleLine className='icon' />12 LPA</h6>
                                    </div>
                                    <div className="apply-btn">
                                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop">Apply Now <FiArrowUpRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>







            <div
                className="modal fade "
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered  modal-dialog-scrollable career-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Graphic Designer
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h3>Job Responsibilities</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam, hic! Placeat tenetur error voluptatem voluptas minima a dolores voluptates quaerat?</p>

                            <form action="">
                                <div className="container">
                                    <h3>Requirements :</h3>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="text" placeholder='Your Name *' />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" placeholder='Your Mail *' />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" placeholder='Your Phone Number *' />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" placeholder='Portfolio Link *' />
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="file-choose">
                                                <label htmlFor="">
                                                    Attach Your Resume / CV
                                                    <input type="file" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className="apply-btn">
                                <button>Apply Now <FiArrowUpRight /></button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >


        </>
    )
}

export default Career