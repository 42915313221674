import React, { useEffect, useRef, useState } from 'react';
import BannerLogo from "../Assets/Resources/banner-logo.svg";
import "../Assets/styles/Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Partner1 from "../Assets/Resources/partner1.svg";
import Partner2 from "../Assets/Resources/partner2.svg";
import Partner3 from "../Assets/Resources/partner3.svg";
import Partner4 from "../Assets/Resources/partner4.svg";
import Partner5 from "../Assets/Resources/partner5.svg";
import Partner6 from "../Assets/Resources/partner6.svg";
import Partner7 from "../Assets/Resources/partner7.svg";
import GridShape from "../Assets/Resources/banner-grid-lines.svg"
import BannerMiniShape1 from "../Assets/Resources/banner-mini-shape1.svg"
import BannerMiniShape2 from "../Assets/Resources/banner-mini-shape2.svg"
import AboutArrow from "../Assets/Resources/about-arrow.svg"
import AssuranceLine1 from "../Assets/Resources/assurance-line-1.svg"
import AssuranceLine2 from "../Assets/Resources/assurance-line-2.svg"
import AssuranceIcon1 from "../Assets/Resources/assurance-icon1.svg"
import WhoWeAreImage from "../Assets/Resources/who-we-image.svg"
import ServiceStar from "../Assets/Resources/service-star.svg"
import ApproachIcon1 from "../Assets/Resources/approach-icon-1.svg"
import ApproachIcon2 from "../Assets/Resources/icons/approach-icon-2.svg"
import ApproachIcon3 from "../Assets/Resources/icons/approach-icon-3.svg"
import ApproachIcon4 from "../Assets/Resources/icons/approach-icon-4.svg"
import ApproachIcon5 from "../Assets/Resources/icons/approach-icon-5.svg"
import ApproachImage from "../Assets/Resources/approach-image1.jpg"
import TechnologiesIcon1 from "../Assets/Resources/technologies-icon1.svg"
import TechnologiesIcon2 from "../Assets/Resources/icons/technologies-icon2.svg"
import TechnologiesIcon3 from "../Assets/Resources/icons/technologies-icon3.svg"
import TechnologiesIcon4 from "../Assets/Resources/icons/technologies-icon4.svg"
import TechnologiesIcon5 from "../Assets/Resources/icons/technologies-icon5.svg"
import WhoWeAreChecked from "../Assets/Resources/who-we-are-checkedbox.png"
import CtaChecked from "../Assets/Resources/cta-checkedbox.png"
import LogoAnimate7 from "../Assets/Resources/logo-sequence/logo7.png"
import AssuranceIcon2 from "../Assets/Resources/icons/assurance-icon2.svg"
import AssuranceIcon3 from "../Assets/Resources/icons/assurance-icon3.svg"
import AssuranceIcon4 from "../Assets/Resources/icons/assurance-icon4.svg"
import AOS from "aos";
import "aos/dist/aos.css";
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import Preloader from '../Components/Preloader';

const Home = () => {


    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: "ease",
        });
    }, []);


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const SplitTypes = document.querySelectorAll('.mini-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                scaleY: 0,
                y: -20,
                transformOrigin: 'top',
                stagger: 0.05
            });
        });
    }, []);

    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.ban-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);



    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.mini-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const elements = document.querySelectorAll('.para-animation');
        elements.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0, y: 20 }, // Start with opacity 0 and slightly below the position
                {
                    opacity: 1,        // End with opacity 1 (fully visible)
                    y: 0,              // Move to the original position
                    duration: 1,       // Adjust duration for smoother transition
                    ease: "power2.out", // Smooth easing
                    scrollTrigger: {
                        trigger: element,
                        start: "top 80%",
                        end: "top 20%",
                        scrub: true,    // One-time animation on scroll
                        markers: false,
                    },
                }
            );
        });
    }, []);



    // Banner Logo Animation

    // const [currentIndex, setCurrentIndex] = useState(0);
    // const images = [
    //     LogoAnimate1, LogoAnimate2, LogoAnimate3, LogoAnimate4, LogoAnimate5,
    //     LogoAnimate6, LogoAnimate7, LogoAnimate8, LogoAnimate9, LogoAnimate10,
    //     LogoAnimate11, LogoAnimate12, LogoAnimate13, LogoAnimate14, LogoAnimate15,
    //     LogoAnimate16, LogoAnimate17, LogoAnimate18, LogoAnimate19, LogoAnimate20, LogoAnimate21
    //     , LogoAnimate22, LogoAnimate23, LogoAnimate24, LogoAnimate25, LogoAnimate26, LogoAnimate27, LogoAnimate28
    //     , LogoAnimate29, LogoAnimate30, LogoAnimate31, LogoAnimate32, LogoAnimate33, LogoAnimate34 // Added missing comma here
    // ];
    // const intervalTime = 50; // Time in milliseconds

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    //     }, intervalTime);

    //     return () => clearInterval(intervalId); // Cleanup on unmount
    // }, [images.length]);




    const settings = {
        infinite: true,
        arrows: false,
        speed: 500,
        autoplay: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200, // For screens smaller than 1200px
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992, // For screens smaller than 992px
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768, // For screens smaller than 768px
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576, // For screens smaller than 576px
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }
        ]
    };





    const [transformStyle, setTransformStyle] = useState({});

    const handleMouseMove = (event) => {
        // Calculate the center of the element
        const { clientX, clientY, currentTarget } = event;
        const { offsetWidth, offsetHeight, offsetLeft, offsetTop } = currentTarget;
        const centerX = offsetLeft + offsetWidth / 2;
        const centerY = offsetTop + offsetHeight / 2;

        // Calculate rotation amount based on mouse position
        const rotateX = (clientY - centerY) / 25;
        const rotateY = (centerX - clientX) / 25;

        // Update transform style
        setTransformStyle({
            transform: `perspective(600px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
            transition: 'transform 0.1s ease-out',
        });
    };

    const handleMouseLeave = () => {
        // Reset the transform when the mouse leaves the element
        setTransformStyle({
            transform: 'perspective(600px) rotateX(0deg) rotateY(0deg)',
            transition: 'transform 0.5s ease-out',
        });
    };

    return (
        <main>

            <Preloader />
            <section className='banner-section'>
                <div className="container">
                    <div className="banner-content">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-title">
                                    <h1 className='ban-title-animation'>Innovating <br />Tomorrow <br />Today</h1>
                                    <p ><b>Prophecy Technologies </b>- Unlock business potential with Prophecy Technologies—driving growth through AI
                                        optimization, digital solutions, and modernized infrastructure for seamless customer
                                        engagement.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-content-right">
                                    {/* <div className="banner-logo" data-aos="zoom-in" data-aos-duration="3000">
                                        <div class="image-container">
                                            <img src={LogoAnimate1} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate1} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate2} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate3} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate4} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate5} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate6} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate7} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate8} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate9} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate10} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate11} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate12} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate13} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate14} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate15} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate16} alt="Image 1" class="logo-ani-image" />
                                            <img src={LogoAnimate17} alt="Image 1" class="logo-ani-image" />
                                        </div>
                                        <div className="image-container">
                                            {images.map((image, index) => (
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Image ${index + 1}`}
                                                    className={`logo-ani-image ${index === currentIndex ? 'active' : ''}`}
                                                    style={{ display: index === currentIndex ? 'block' : 'none' }} // Show only the active image
                                                />
                                            ))}
                                        </div>
                                        <div className="image-container">
                                            <img src={BannerLogo} alt="" />
                                        </div>
                                    </div> */}

                                    <div
                                        className="banner-logo"
                                        data-aos="zoom-in"
                                        data-aos-duration="3000"
                                        onMouseMove={handleMouseMove}
                                        onMouseLeave={handleMouseLeave}
                                        style={transformStyle}
                                    >
                                        <div className="image-container">
                                            <img src={LogoAnimate7} alt="Logo" />
                                        </div>
                                    </div>


                                    <div className="grid-shape">
                                        <img src={GridShape} alt="" />
                                    </div>
                                    <div className="banner-mini-shape-1">
                                        <img src={BannerMiniShape1} alt="" />
                                    </div>
                                    <div className="banner-mini-shape-2">
                                        <img src={BannerMiniShape2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="partners-slider ">
                            <Slider {...settings} className='partners-slider-content'>
                                <div>
                                    <div className="image" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src={Partner1} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="image" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src={Partner2} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="image" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src={Partner3} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="image" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src={Partner4} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="image" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src={Partner5} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="image" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src={Partner6} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="image servincen" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src={Partner7} alt="" />
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="banner-ellipse1" ></div>
                    <div className="partners-shape" ></div>
                </div>
            </section>

            <div className="gradient-bg">
                {/* about section */}
                <section className='AboutSection sec-padding'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="content">
                                    <div className="left-subtitle">
                                        <h6 data-aos="fade-right" data-aos-duration="2000">About Us</h6>
                                    </div>
                                    <div className="large-title">
                                        <h1 className='mini-title-animation'>Welcome to <br />Prophecy <br />Technologies</h1>
                                    </div>
                                    <p data-aos="fade-right" data-aos-duration="2000" className='small-title'>Shaping the Future of <br />Business Experiences</p>
                                    <div className="shape" data-aos="zoom-in" data-aos-duration="2000">
                                        <img src={AboutArrow} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="right-content">
                                    <p className='para-animation'>At Prophecy Technologies, we are on a mission to transform how businesses interact with their customers. In a rapidly evolving digital landscape, bridging the gap between business goals and customer experiences is not just a challenge; it’s an opportunity. We empower businesses to embrace change, leverage data-driven insights, and optimize customer journeys through the power of AI, connected data, and actionable insights. Our vision is to help you deliver personalized, relevant, and engaging experiences that drive both business growth and customer loyalty.</p>
                                    <p className=' para-animation'> With a broad range of services and cutting-edge technology solutions, Prophecy Technologies is your partner in navigating digital transformation. We pride ourselves on delivering results that matter. Welcome to a new era of business success, powered by Prophecy Technologies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='assurance_section sec-padding'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="title-box" data-aos="zoom-out" data-aos-duration="2000">
                                    <div className="sec-title">
                                        <h1 className='mini-title-animation'>The 4 A's of Prophecy</h1>
                                        <div className="center-subtitle">
                                            <h6>Our Assurance</h6>
                                        </div>
                                    </div>
                                    <div className="shape">
                                        <div className="line1">
                                            <img src={AssuranceLine1} alt="" />
                                        </div>
                                        <div className="line2">
                                            <img src={AssuranceLine2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="box-content box1 rainbow" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="icon">
                                        <img src={AssuranceIcon1} alt="" />
                                    </div>
                                    <h1>Attunement</h1>
                                    <p>Harmonizing goals <br />with action.</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="box-content box2 rainbow" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
                                    <div className="icon">
                                        <img src={AssuranceIcon2} alt="" />
                                    </div>
                                    <h1>Answerability</h1>
                                    <p>Standing Behind Every Promise</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="box-content box1 rainbow" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1500">
                                    <div className="icon">
                                        <img src={AssuranceIcon3} alt="" />
                                    </div>
                                    <h1>Adaptability</h1>
                                    <p>Thriving in Change, Leading Innovation</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="box-content box2 rainbow" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="2000">
                                    <div className="icon">
                                        <img src={AssuranceIcon4} alt="" />
                                    </div>
                                    <h1>Attainment</h1>
                                    <p>Reaching New Heights Through Collabration</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="gradient-bg-radial-1"></div>
                <div className="gradient-bg-radial-2"></div>
            </div>

            <section className='who-we-are-section sec-padding'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="left-image" data-aos="fade-right" data-aos-duration="2000">
                                <img src={WhoWeAreImage} alt="" />
                                <div className="gradient-shape"></div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="content">
                                <div className="left-subtitle">
                                    <h6 data-aos="fade-down" data-aos-duration="2000">Who we are</h6>
                                </div>
                                <div className="large-title">
                                    <h1 className='mini-title-animation'>Visionaries <br />Of Innovation</h1>
                                </div>
                                <p className='para-animation'>Prophecy Technologies was founded with a clear vision: to help businesses
                                    unlock their full potential by offering innovative, tailored solutions. Our founders
                                    identified a critical gap between the way businesses operate and how they
                                    engage with customers. Today, we help bridge that gap by offering
                                    forward-thinking strategies and technologies that turn visions into reality.</p>
                                <p className='para-animation'>Our approach is rooted in a deep understanding of both business needs and
                                    customer expectations. By combining technical expertise with a commitment to
                                    innovation, we enable businesses to create seamless, personalized experiences
                                    for their customers. With 600+ resources, 6+ global locations, 200+ successfully completed projects,
                                    and 100+ developer teams, Prophecy Technologies has the expertise, scale, and
                                    resources to help your business grow, thrive, and transform.</p>
                            </div>
                        </div>
                    </div>
                    <div className="checked-shape">
                        <img src={WhoWeAreChecked} alt="" />
                    </div>
                </div>
            </section>

            <div className="gradient-bg-2">
                <section className='our-core-services sec-padding'>
                    <div className="container">
                        <div className="row">
                            <div className="sec-title">
                                <div className="center-subtitle">
                                    <h6 data-aos="fade-down" data-aos-duration="2000">Our Core Services</h6>
                                </div>
                                <div className="large-title">
                                    <h1 className='mini-title-animation'>Powering Your <br />Business Growth</h1>
                                </div>
                                <p className='para-animation'>At Prophecy Technologies, we offer a comprehensive range of services designed
                                    to help businesses excel in an increasingly digital and connected world. Our
                                    team of experts collaborates closely with clients to understand their needs and
                                    craft solutions that deliver measurable results. Here’s a closer look at our core services:
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="service-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="icon">
                                        <img src={ServiceStar} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>AI & Data Analytics</h1>
                                        <p>Harness the power of AI and advanced analytics with Prophecy Technologies. We deliver data-driven solutions, leveraging machine learning and big data to uncover actionable insights. Optimize decision-making, improve operational efficiency, and accelerate growth with customized strategies tailored to your business goals. Turn raw data into meaningful innovation with us.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="service-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
                                    <div className="icon">
                                        <img src={ServiceStar} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Digital Acceleration</h1>
                                        <p>Embrace digital transformation with Prophecy Technologies. We streamline your business processes, enhance user experiences, and unlock new revenue streams through cutting-edge digital solutions. From cloud adoption to workflow automation, we ensure rapid implementation and seamless integration, driving your business towards a future-ready digital ecosystem.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="service-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1500">
                                    <div className="icon">
                                        <img src={ServiceStar} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Application Modernization</h1>
                                        <p>Modernize legacy applications with Prophecy Technologies to align with evolving business needs. We redesign, refactor, and re-platform outdated software using cloud-native technologies and microservices. Enhance scalability, reduce operational costs, and improve performance while maintaining continuity. Future-proof your IT ecosystem with our tailored modernization solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="service-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="icon">
                                        <img src={ServiceStar} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Database Modernization
                                        </h1>
                                        <p>Upgrade your data infrastructure with our comprehensive database modernization services. Prophecy Technologies ensures seamless migration, optimized performance, and enhanced security for legacy databases. Leverage cloud-based or hybrid solutions to reduce costs and boost efficiency, enabling your business to scale effortlessly and adapt to ever-changing demands.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="service-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
                                    <div className="icon">
                                        <img src={ServiceStar} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Infrastructure Modernization</h1>
                                        <p>Transform your IT infrastructure for agility and resilience. Prophecy Technologies provides end-to-end infrastructure modernization, integrating cloud platforms, advanced automation, and scalable systems. Optimize resource utilization, enhance security, and support dynamic business needs with our robust solutions designed for sustainable growth.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="service-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1500">
                                    <div className="icon">
                                        <img src={ServiceStar} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Cybersecurity</h1>
                                        <p>Protect your business with Prophecy Technologie’s comprehensive cybersecurity solutions. From risk assessments to advanced threat mitigation, we secure your digital assets against evolving cyber threats. Safeguard sensitive data, maintain regulatory compliance, and build customer trust with proactive and adaptive security strategies tailored to your needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="service-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="icon">
                                        <img src={ServiceStar} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Site Reliability Services</h1>
                                        <p>Ensure unparalleled reliability with Prophecy Technologie’s Site Reliability Services. We combine robust monitoring, automated incident management, and performance optimization to keep your applications running seamlessly. Minimize downtime, enhance user experience, and drive operational excellence with solutions that deliver high availability and resilience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="service-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
                                    <div className="icon">
                                        <img src={ServiceStar} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Automation</h1>
                                        <p>Streamline operations with Prophecy Technologies' automation solutions. From robotic process automation to intelligent workflows, we eliminate inefficiencies and enhance productivity. Simplify complex processes, reduce manual errors, and drive innovation with automation strategies tailored to your unique business challenges and goals.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="service-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1500">
                                    <div className="icon">
                                        <img src={ServiceStar} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Staffing & IT Consulting</h1>
                                        <p>Prophecy Technologies bridges talent gaps with expert staffing and IT consulting services. We provide highly skilled professionals and strategic guidance to meet your project needs. Enhance operational efficiency, scale effectively, and achieve your IT goals with our customized, results-driven solutions designed for success.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="checked-shape">
                            <img src={CtaChecked} alt="" />
                        </div>
                    </div>
                </section>
                <div className="gradient-bg-radial-1"></div>
                <div className="gradient-bg-radial-2"></div>
                <div className="gradient-bg-radial-3"></div>
            </div>



            <section className='approach-section sec-padding'>
                <div className="container">
                    <div className="row">
                        <div className="sec-title">
                            <div className="center-subtitle">
                                <h6 data-aos="fade-down" data-aos-duration="2000" >Our Approach</h6>
                            </div>
                            <div className="large-title">
                                <h1 className='mini-title-animation'>How We Deliver<br />Excellence</h1>
                            </div>
                            <p className='para-animation'>At Prophecy Technologies, we believe in a results-driven approach. Our process
                                is built on collaboration, communication, and continuous improvement, ensuring
                                that we deliver exceptional outcomes for every client.</p>
                        </div>
                    </div>
                    <div className="box-content">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="approach-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="icon">
                                        <img src={ApproachIcon1} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Customer-Centric Solutions</h1>
                                        <p>We start by listening to your needs. Every business is unique, and so are its
                                            challenges. Our team works closely with you to understand your goals and pain
                                            points, tailoring solutions that deliver measurable impact.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="approach-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
                                    <div className="icon">
                                        <img src={ApproachIcon2} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Innovative Strategies</h1>
                                        <p>Innovation is at the heart of everything we do. We leverage the latest
                                            technologies and trends to create cutting-edge solutions that keep your business
                                            ahead of the curve. Whether it's integrating AI into your customer experience
                                            strategy or modernizing your IT infrastructure, we provide innovative, future-proof
                                            solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="approach-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1500">
                                    <div className="icon">
                                        <img src={ApproachIcon3} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Data-Driven Insights</h1>
                                        <p>Making informed decisions is key to success in today’s data-driven world. Prophecy Technologies provides actionable insights by leveraging connected data and advanced analytics. Our data-driven approach ensures that you’re not just reacting to trends—you’re anticipating them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="approach-box-large approach-box" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className='content'>
                                        <div className="icon">
                                            <img src={ApproachIcon4} alt="" />
                                        </div>
                                        <div className="content">
                                            <h1>Collaborative Partnerships</h1>
                                            <p>We don’t just work for you; we work with you. Our team becomes an extension of
                                                your own, providing ongoing support and collaboration throughout every phase of
                                                the project. From concept to execution, we are your partners in progress.</p>
                                        </div>
                                    </div>
                                    <div className="image" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1000">
                                        <img src={ApproachImage} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
                                <div className="approach-box">
                                    <div className="icon">
                                        <img src={ApproachIcon5} alt="" />
                                    </div>
                                    <div className="content">
                                        <h1>Continuous Improvement</h1>
                                        <p>Our commitment to excellence doesn’t stop when a project is delivered. We
                                            believe in the power of continuous improvement, constantly optimizing and
                                            refining our solutions to ensure that you stay ahead in an ever-changing
                                            landscape</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='technologies-section sec-padding' id='technology-sec-desktop'>
                <div className="container">
                    <div className="row">
                        <div className="shape" >
                            <div data-aos="zoom-in" data-aos-duration="1000">
                                <div className="circle1"></div>
                                <div className="circle2"></div>
                                <div className="circle3"></div>
                            </div>
                            <div className="sec-title">
                                <div className="large-title">
                                    <h1 className='mini-title-animation'>Why Choose <br />Prophecy <br />Technologies?</h1>
                                </div>
                                <p className='para-animation text-center'>Choosing the right partner is critical to your success. Here’s why Prophecy
                                    Technologies stands out from the rest:</p>
                            </div>

                            <div className="content-one box-content" data-aos="zoom-in" data-aos-duration="2000">
                                <div className="icon">
                                    <img src={TechnologiesIcon1} alt="" />
                                </div>
                                <div className="content">
                                    <h1>Expertise Across Industries</h1>
                                    <p>Our team has deep expertise in a wide range of industries, allowing us to offer
                                        tailored solutions that meet the specific needs of your business. From finance to
                                        healthcare, retail to manufacturing, we’ve got you covered.
                                    </p>
                                </div>
                            </div>
                            <div className="content-two box-content" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="500">
                                <div className="icon">
                                    <img src={TechnologiesIcon2} alt="" />
                                </div>
                                <div className="content">
                                    <h1>End-to-End Solutions</h1>
                                    <p>We offer comprehensive, end-to-end solutions that address every aspect of your
                                        digital transformation. Whether you need help with Adobe integration, SAP
                                        services, or Cybersecurity, we provide the expertise and resources you need to
                                        succeed.
                                    </p>
                                </div>
                            </div>
                            <div className="content-three box-content" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1000">
                                <div className="icon">
                                    <img src={TechnologiesIcon3} alt="" />
                                </div>
                                <div className="content">
                                    <h1>Global Reach</h1>
                                    <p>With over 6+ locations across the globe, we have the resources and scale to
                                        support businesses of all sizes. Whether you're a local company or a global
                                        enterprise, Prophecy Technologies delivers consistent, high-quality results.</p>
                                </div>
                            </div>
                            <div className="content-four box-content" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="500">
                                <div className="icon">
                                    <img src={TechnologiesIcon4} alt="" />
                                </div>
                                <div className="content">
                                    <h1>Proven Track Record</h1>
                                    <p>With over 200+ successful projects and 100+ developer teams, we have a
                                        proven track record of delivering excellence. Our clients trust us to deliver on our
                                        promises, and we are proud of the long-term partnerships we’ve built.</p>
                                </div>
                            </div>
                            <div className="content-five box-content" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1000">
                                <div className="icon">
                                    <img src={TechnologiesIcon5} alt="" />
                                </div>
                                <div className="content">
                                    <h1>Commitment to Innovation</h1>
                                    <p>Innovation is at the core of everything we do. We stay ahead of the latest trends
                                        and technologies, providing solutions that are not just current, but future-proof.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className='technologies-section sec-padding' id='technology-sec-mobile'>
                <div className="container">
                    <div className="sec-title">
                        <div className="large-title">
                            <h1 className='mini-title-animation'>Why Choose <br />Prophecy <br />Technologies?</h1>
                        </div>
                        <p>Choosing the right partner is critical to your success. Here’s why Prophecy Technologies stands out from the rest:</p>
                    </div>
                    <div className="row">
                        <div className="content-one box-content">
                            <div className="icon">
                                <img src={TechnologiesIcon1} alt="" />
                            </div>
                            <div className="content">
                                <h1>Expertise Across Industries</h1>
                                <p>Our team has deep expertise in a wide range of industries, allowing us to offer
                                    tailored solutions that meet the specific needs of your business. From finance to
                                    healthcare, retail to manufacturing, we’ve got you covered.</p>
                            </div>
                        </div>
                        <div className="content-two box-content">
                            <div className="icon">
                                <img src={TechnologiesIcon2} alt="" />
                            </div>
                            <div className="content">
                                <h1>End-to-End Solutions</h1>
                                <p>We offer comprehensive, end-to-end solutions that address every aspect of your
                                    digital transformation. Whether you need help with Adobe integration, SAP
                                    services, or Cybersecurity, we provide the expertise and resources you need to
                                    succeed.</p>
                            </div>
                        </div>
                        <div className="content-three box-content">
                            <div className="icon">
                                <img src={TechnologiesIcon3} alt="" />
                            </div>
                            <div className="content">
                                <h1>Global Reach</h1>
                                <p>With over 6+ locations across the globe, we have the resources and scale to
                                    support businesses of all sizes. Whether you're a local company or a global
                                    enterprise, Prophecy Technologies delivers consistent, high-quality results</p>
                            </div>
                        </div>
                        <div className="content-four box-content">
                            <div className="icon">
                                <img src={TechnologiesIcon4} alt="" />
                            </div>
                            <div className="content">
                                <h1>Proven Track Record</h1>
                                <p>With over 200+ successful projects and 100+ developer teams, we have a
                                    proven track record of delivering excellence. Our clients trust us to deliver on our
                                    promises, and we are proud of the long-term partnerships we’ve built.</p>
                            </div>
                        </div>
                        <div className="content-five box-content">
                            <div className="icon">
                                <img src={TechnologiesIcon5} alt="" />
                            </div>
                            <div className="content">
                                <h1>Commitment to Innovation</h1>
                                <p>Innovation is at the core of everything we do. We stay ahead of the latest trends
                                    and technologies, providing solutions that are not just current, but future-proof.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>









            <section className='cta-section common-btn sec-padding'>
                <div className="container">
                    <div className="row">
                        <div className="sec-title">
                            <div className="large-title">
                                <h1 className='mini-title-animation'>Let's Build Your <br />Future Together</h1>
                            </div>
                            <p className='para-animation'>At Prophecy Technologies, we believe that every business has the potential to
                                achieve greatness. Our mission is to help you unlock that potential by providing
                                the tools, technologies, and strategies needed to succeed in today’s competitive
                                landscape. If you're ready to take the next step in your business’s digital transformation,
                                contact us today and discover how Prophecy Technologies can help you achieve
                                your goals.</p>
                            <a href="contact">
                                <button data-aos="zoom-in" data-aos-duration="1000">Get Started</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </main >
    );
};

export default Home;
